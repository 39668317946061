export enum TEST_IDS {
	LOGIN_FORM = 'login-form',
	NOT_FOUND_PAGE = 'notFoundPage',
	ORDERS_PAGE = 'ordersPage',
	STORE_ADDRESSES_PAGE = 'storeAddressesPage',
	USERS_PAGE = 'usersPage',
	FORGOT_PASSWORD_PAGE = 'forgot-password-page',
	DOCUMENTS_PAGE = 'document-page',
	ACCOUNTS_PAGE = 'account-page',
	PAYMENT_SCHEDULE_PAGE = 'payment-schedule-page',
	BULK_UPDATE_PAGE = 'bulk-update-page',
	POWER_OUTAGES_PAGE = 'powerOutage',
	MODAL = 'modal-root',
	MODAL_OVERLAY = 'modal-overlay',
	MODAL_CLOSE = 'modal-close',
	MODAL_BUTTON_CANCEL = 'modal-button-cancel',
	MODAL_BUTTON_CONFIRM = 'modal-button-confirm',
	LOADER = 'loader',
	ONLOAD_DOTTED = 'onload-dotted',
	OPEN_GET_STARTED = 'button-open-get-started',
	OPEN_FAQ = 'button-open-faq',
	LOGO = 'logo-default',
	LOGO_PARTNER = 'logo-partner',
	LOGO_PARTNER_MP = 'logo-partner-mp',
	GET_STARTED_MODAL = 'get-started-modal',
	LOGOUT_BUTTON_HEADER = 'logout-button-header',
	LOGOUT_BUTTON_MENU = 'logout-button-menu',
	GET_STARTED_GREET_SCREEN = 'get-started-greet-screen',
	GET_STARTED_APPLY_ALL = 'get-started-apply-all',
	GET_STARTED_APPROVE_ORDER = 'get-started-approve-order',
	GET_STARTED_COUNTERPARTY = 'get-started-counterparty',
	GET_STARTED_DATEPICKER = 'get-started-date',
	GET_STARTED_EDIT = 'get-started-edit',
	GET_STARTED_EXPORT_EXCEL = 'get-started-export-excel',
	GET_STARTED_ID = 'get-started-id',
	GET_STARTED_STATUS = 'get-started-status',
	GET_STARTED_NEXT_BUTTON = 'get-started-next-button',
	GET_STARTED_PREV_BUTTON = 'get-started-prev-button',
	GET_STARTED_PROMO_PRODUCTS = 'get-started-promo-products',
	GET_STARTED_PROMO_DELIVERY = 'get-started-promo-delivery',
	GET_STARTED_PARTNER_TOTAL = 'get-started-partner-total',
	GET_STARTED_BASE_AGENT_TOTAL = 'get-started-base-agent-total',
	GET_STARTED_AGENT_TOTAL_WITH_VAT = 'get-started-agent-total-with-vat',
	MENU_COLLAPSE_TOGGLE_BUTTON = 'menu-collapse-toggle-button',
	CREATE_STORE_ADDRESS_DATE = 'create-store-address-date',
	CREATE_STORE_ADDRESS_STORE_ADDRESS_ID = 'create-store-address-storeAddressId',
	CREATE_STORE_ADDRESS_STORE_ID = 'create-store-address-store-id',
	CREATE_STORE_ADDRESS_STORE_NAME = 'create-store-address-store-name',
	CREATE_STORE_ADDRESS_CITY = 'create-store-address-city',
	CREATE_STORE_ADDRESS_ADDRESS = 'create-store-address-address',
	CREATE_STORE_LEGAL_ADDRESS = 'create-store-legal-address',
	CREATE_STORE_ADDRESS_TAXT_ID = 'create-store-address-tax-id',
	CREATE_STORE_ADDRESS_COMPANY_NAME = 'create-store-address-company-name',
	CREATE_STORE_ADDRESS_PERCENTAGE = 'create-store-address-partnerCommissionPercentage',
	CREATE_STORE_ADDRESS_PICKUP = 'create-store-address-pickUpCommissionPercentage',
	CREATE_STORE_ADDRESS_ACCOUNTING_TYPE = 'create-store-address-accountingType',
	CREATE_STORE_ADDRESS_MENAGER_NAME = 'create-store-address-accountManagerName',
	CREATE_STORE_ADDRESS_INCIDENTS_PAID_BY_GLOVO = 'create-store-address-incidentsPaidByGlovo',
	CREATE_STORE_ADDRESS_DAILY_PAYMENTS = 'create-store-address-daily-payments',
	CREATE_STORE_ADDRESS_WAITING_TIME_FEE = 'create-store-address-waitingTimeFee',
	CREATE_STORE_ADDRESS_WAITING_TIME_START_DATE = 'create-store-address-waitingTimeFeeStartDate',
	CREATE_STORE_ADDRESS_EMAIL = 'create-store-address-email',
	CREATE_STORE_ADDRESS_EMAIL2 = 'create-store-address-emai2',
	CREATE_STORE_ADDRESS_REASON = 'create-store-address-reason',
	CREATE_STORE_ADDRESS_PARTNER_CONTRACT_NUMBER = 'create-store-address-partnerContractNumber',
	CREATE_STORE_ADDRESS_AGREEMENT_TYPE = 'create-store-address-agreement-type',
	CREATE_STORE_ADDRESS_PROMO_CALCULATION_TYPE = 'create-store-address-promo-calculation-type',
	CREATE_STORE_ADDRESS_PROMO_PRODUCT_CALCULATION_TYPE = 'create-store-address-promo-product-calculation-type',
	CREATE_STORE_ADDRESS_IBAN = 'create-store-address-iban',
	CHECKBOX = 'checkbox',
	CHECKBOX_CHECKED = 'checkbox-checked',
	ORDERS_CONTAINER = 'orders-container',
	ORDERS_EXPORTS_ROOT = 'orders-exports-root',
	ORDERS_TABLE_HEAD = 'orders-table-head',
	ORDERS_TABLE_HEAD_SORT_CELL = 'orders-table-head-sort-cell',
	ORDERS_TABLE_HEAD_ACTIVE_SORT_CELL = 'orders-table-head-active-sort-cell',
	ORDERS_TABLE_HEAD_ACTIVE_SORT_REVERSE_CELL = 'orders-table-head-active-sort-reverse-cell',
	ORDERS_TABLE_BODY = 'orders-table-body',
	ORDERS_TABLE_BODY_ROW = 'orders-table-body-row',
	ORDERS_FILTERS = 'order-filters',
	ORDERS_FILTERS_CODE = 'order-filters-code-textbox',
	ORDERS_FILTERS_TRACKING_NUMBER = 'order-filters-trackingNumber-textbox',
	ORDERS_FILTERS_STORE_NAME = 'order-filters-store-name-select',
	ORDERS_FILTERS_STORE_ADDRESS = 'order-filters-store-store-address-select',
	ORDERS_FILTERS_COMPANY_NAME = 'order-filters-store-company-name-select',
	ORDERS_FILTERS_ORDER_STATUS = 'order-filters-store-order-status-select',
	ORDERS_FILTERS_ACCOUNTING_TYPES = 'order-filters-store-accounting-types-select',
	ORDERS_FILTERS_IS_MARKETPLACE_ORDER = 'order-filters-store-isMarketplaceOrder',
	ORDERS_FILTERS_CONTRACT_NUMBER = 'order-filters-store-contract-number',
	ACCOUNTS_INVOICE_TABLE = 'accounts-invoice-table',
	ACCOUNTS_INVOICE_TABLE_HEAD = 'accounts-invoice-table-head',
	ACCOUNTS_DEBT_TABLE = 'accounts-debt-table',
	ACCOUNTS_DEBT_TABLE_HEAD = 'accounts-debt-table-head',
	ACCOUNTS_PAYMENT_TABLE = 'accounts-payment-table',
	ACCOUNTS_PAYMENT_TABLE_HEAD = 'accounts-payment-table-head',
	PAYMENT_SCHEDULE_TABLE_HEAD = 'payment-schedule-table-head',
	PAYMENT_SCHEDULE_TABLE_ROW = 'payment-schedule-table-row',
	BULK_UPDATE_FILE = 'bulk-update-file',
	PAGINATION = 'pagination',
	PAGINATION_PREV = 'pagination-prev',
	PAGINATION_NEXT = 'pagination-next',
	LOAD_MORE = 'load-more',
	DATEPICKER = 'datepicker-component',
	SELECT_CANCEL_BUTTON = 'select-cancel-button',
	SELECT_APPLY_BUTTON = 'select-apply-button',
	MODAL_ORDER_AMOUNT = 'modal-order-amount-textfield',
	MODAL_ORDER_CHARGED_TO_PARTNER = 'modal-order-chargedToPartner-textfield',
	MODAL_ORDER_RECEIVED_FUNDS = 'modal-order-receivedFunds-textfield',
	MODAL_ORDER_PAYMENT_METHOD = 'modal-order-paymentMethod-select',
	MODAL_ORDER_DELIVERY_FEE = 'modal-order-deliveryFee-textfield',
	MODAL_ORDER_FINAL_STATUS = 'modal-order-finalStatus-select',
	MODAL_ORDER_COMMENT = 'modal-order-comment-textfield',
	MODAL_ORDER_FILE = 'modal-order-file-input',
	MODAL_ORDER_FORM_AMENDMENTS = 'modal-order-form-amendments',
	CHAT_INPUT = 'chat-input',
	PASSWORD_INPUT = 'password-inpue',
	PASSWORD_REPEAT_INPUT = 'password-repeat-input',
	USERS_PAGE_MORE_BUTTON = 'users-page-more-button',
	USERS_PAGE_EMAIL_INPUT = 'users-page-email-input',
	STORE_ADDRESSES_PAGE_CLOSE_ITEM = 'store-addresses-page-close_item',
	STORE_ADDRESSES_HISTORY_ROW = 'store-addresses-history-row',
	ORDERS_TOTALS_BUTTON_BASE_AGENT = 'orders-totals-button-baseAgentTotal',
	ORDERS_TOTALS_BUTTON_AGENT_TOTAL = 'orders-totals-button-agentTotalWithVat',
	ORDERS_TOTALS_BUTTON_PARTNER_TOTAL = 'orders-totals-button-partnerTotal',
	ORDERS_TOTALS_BUTTON_PROMO_PRODUCTS = 'orders-totals-button-promoProducts',
	ORDERS_TOTALS_BUTTON_PROMO_DELIVERY = 'orders-totals-button-promoDelivery',
	ORDERS_TOTALS_BUTTON_DELIVERY_FEE = 'orders-totals-button-deliveryFee',
	COUNTRY_SWITCHER_SELECT = 'counter-switcher-select',
	EDRPOU_SELECT = 'edrpou-switcher-select',
	LANG_SWITCHER = 'lang-switcher',
	DOCUMENT_FRAME = 'doc-frame',
	FILE_LOADED = 'file-loaded',
	DOCUMENTS_TABLE_HEAD = 'doc-table-head',
	DOCUMENTS_TABLE_BODY_ROW = 'doc-table-body-row',
	DOCUMENTS_TABLE = 'doc-table',
	ACCOUNTS_TABLE_BODY_DEBT_ROW = 'accounts-debt-row',
	ACCOUNTS_TABLE_BODY_DEBT_ROW_TOGGLE = 'accounts-debt-row-toggle',
	ACCOUNTS_TABLE_BODY_DEBT_ROW_CHILD = 'accounts-debt-row-child',
	ACCOUNTS_TABLE_BODY_INVOICE_ROW = 'accounts-invoice-row',
	ACCOUNTS_TABLE_BODY_INVOICE_ROW_CHILD = 'accounts-invoice-row-child',
	ACCOUNTS_TABLE_BODY_PAYMENT_ROW = 'accounts-payment-row',
	ACCOUNTS_FILTERS_COMPANY_NAME = 'accounts-company-filter',
	ACCOUNTS_FILTERS_CONTRACT_NUMBER = 'accounts-contract-filter',
	ACCOUNTS_FILTERS_STATUS = 'accounts-status-filter',
	ACCOUNTS_FILTERS_DEBT_COMPANY_NAME = 'accounts-payment-company-filter',
	ACCOUNTS_FILTERS_PAYMENT_STATUS = 'accounts-payment-status-filter',
	ACCOUNTS_FILTERS_STORE_NAME = 'accounts-payment-store-name-filter',
	ACCOUNTS_FILTERS_STORE_ADDRESS = 'accounts-payment-store-address-filter',
	DOCUMENTS_FILTERS_STATUS = 'doc-filter-status',
	DOCUMENTS_FILTERS_EDRPOU = 'doc-filter-edrpou',
	DOCUMENTS_FILTERS_GROUP = 'doc-filter-group',
	DOCUMENTS_FILTERS_CATEGORIES = 'doc-filter-categories',
	PAYMENT_SCHEDULE_FILTER = 'payment-schedule-categories',
	NOTIFICATIONS_BUTTON = 'notifications-button',
	NOTIFICATIONS_COUNT = 'notifications-count',
	NOTIFICATIONS_LINK = 'notifications-link',
	NOTIFICATIONS_DEBT_EXPIRED_TEXT = 'notifications-debt-expired-text',
	NOTIFICATIONS_DEBT_TOTAL_TEXT = 'notifications-debt-total-text',
	CONTRACT_GENERATION_PAGE = 'contract-generation-page',
	SHOW_DOCUMENTS = 'show-documents',
	WORKING_HOURS_ADD = 'working-hours-add',
	CREATE_DOCUMENT_SEARCH = 'create-document-search',
	RELEASE_MODAL_TITLE = 'release-modal-title',
	ACTIONS = 'actions-dots',
	LIST_ITEM_OPTION = 'create-user-list-item-option',
	SELECTED_LIST = 'create-user-selected-list',
	REMOVE_LIST_ITEM = 'create-user-remove-selected-list-item',
	QUESTION_NUMBER = 'questionnaire_question_number',
}
