import { gql } from '@apollo/client';

export const GET_ORDERS = gql`
	query GetOrders($page: Int, $pageSize: Int, $filter: OrderFilter) {
		orders(page: $page, pageSize: $pageSize, filter: $filter) {
			totalPages
			total
			orderDtos {
				id
				code
				activationTime
				storeAddressId
				storeName
				companyName
				amount
				partnerCommissionPercentage
				finalStatus
				cancelReason
				partnerResolution
				chargedToPartner
				paymentMethod
				orders
				orderStatus
				dispatchingTime
				originAmount
				originChargedToPartner
				originPaymentMethod
				promotionAssumedByPartner
				productDiscountAssumedByPartner
				deliveryDiscountAssumedByPartner
				deliveryPromotionAssumedByPartner
				deliveryDiscountSSP
				deliveryDiscountAssumedByPartner100
				deliveryDiscountAssumedByPartnerAndGlovo
				taxId
				deliveryFee
				isMarketplaceOrder
				partnerPayment
				receivedFunds
				originDeliveryFee
				originReceivedFunds
				originFinalStatus
				originDeliveryDiscountAssumedByPartner
				originProductDiscountAssumedByPartner
				originDeliveryPromotionAssumedByPartner
				originDeliveryDiscountSSP
				originDeliveryDiscountAssumedByPartner100
				originDeliveryDiscountAssumedByPartnerAndGlovo
				originPromotionAssumedByPartner
				originWaitingTime
				expectedPaymentDate
				lastPaymentDate
				lastDiffInputDate
				promoCalculationType
				deliveryDiscountPaidByPartner
				serviceFee
				waitingTime
				approvedAfterPeriodDto {
					approvedAt
					amount
					deliveryDiscountAssumedByPartner
					deliveryPromotionAssumedByPartner
					productDiscountAssumedByPartner
					deliveryDiscountPaidByPartner
					promotionAssumedByPartner
					chargedToPartner
					isOriginalPaymentMethod
					partnerPayment
					receivedFunds
					deliveryFee
					waitingTime
				}
				startPeriodDate
				endPeriodDate
				disputes {
					amount
					type
					disputeCreationDate
				}
			}
		}
	}
`;

export const GET_DATE_RANGE = gql`
	query DateRange {
		dateRange {
			min
			max
		}
	}
`;

export const GET_DATE_PICKER_SCHEDULE = gql`
	query DatePickerSchedule($filter: AccountingTypeFilter!) {
		datePickerSchedule(filter: $filter) {
			startDate
			endDate
		}
	}
`;

export const GET_STORE_NAMES = gql`
	query StoreNames($filter: OrderFilter!) {
		storeNames(filter: $filter)
	}
`;

export const GET_COMPANY_NAMES = gql`
	query CompanyNames($filter: OrderFilter!) {
		companyNames(filter: $filter) {
			companyName
			taxId
		}
	}
`;

export const GET_STORE_ADDRESS = gql`
	query StoreAddress($filter: OrderFilter!) {
		storeAddress(filter: $filter) {
			id
			address
			contractNumber
		}
	}
`;

export const GET_TOTALS = gql`
	query Totals($filter: OrderFilter!) {
		totals(filter: $filter) {
			main {
				chargedToPartnerTotal
				commissionTotal
				orderTotal
				paidCashTotal
				productDiscountAssumedByPartnerTotal
				productDiscountAssumedByPartnerTotalWithVat
				productDiscountAssumedByPartnerTotalVat
				deliveryDiscountAssumedByPartnerTotal
				deliveryDiscountAssumedByPartnerTotalWithVat
				deliveryDiscountAssumedByPartnerTotalVat
				deliveryFeeTotal
				deliveryDiscountTotal
				partnerPaymentTotal
				commissionTotalVat
				commissionTotalWithVat
				additionalCommissionWithoutVat
				productDiscount
				agentFeeCalculationBase
				partnerPaymentVat
				partnerPaymentWithoutVat
				partnerPaymentWithVat
				disputeAmount
				waitingTime
			}
			period {
				chargedToPartnerTotal
				commissionTotal
				orderTotal
				paidCashTotal
				productDiscountAssumedByPartnerTotal
				productDiscountAssumedByPartnerTotalWithVat
				productDiscountAssumedByPartnerTotalVat
				deliveryDiscountAssumedByPartnerTotal
				deliveryDiscountAssumedByPartnerTotalWithVat
				deliveryDiscountAssumedByPartnerTotalVat
				deliveryFeeTotal
				deliveryDiscountTotal
				partnerPaymentTotal
				commissionTotalVat
				commissionTotalWithVat
				additionalCommissionWithoutVat
				productDiscount
				agentFeeCalculationBase
				partnerPaymentVat
				partnerPaymentWithoutVat
				partnerPaymentWithVat
				waitingTime
			}
		}
	}
`;

export const APPROVE_ORDER_BY_ID = gql`
	mutation ApproveOrder($orderId: Long) {
		approveOrder(orderId: $orderId) {
			approvedAt
			approvedBy
		}
	}
`;

export const EDIT_ORDER = gql`
	mutation SubmitOrderAmendment($input: OrderPartnerAmendmentInputDto) {
		submitOrderAmendment(input: $input) {
			id
			orderId
			effectiveDate
			createdAt
			createdBy
			amount
			chargedToPartner
			paymentMethod
			partnerPayment
			productDiscountAssumedByPartner
			deliveryDiscountAssumedByPartner
			deliveryPromotionAssumedByPartner
			deliveryPromotionAssumedByPartner
			deliveryDiscountSSP
			deliveryDiscountAssumedByPartner100
			deliveryDiscountAssumedByPartnerAndGlovo
			acceptedAt
			acceptedBy
			rejectedAt
			rejectedBy
			comment
			receivedFunds
			deliveryFee
			finalStatus
			acceptedAfterOrderPeriod
			orderStatus
			waitingTime
		}
	}
`;

export const GET_COMMENTS = gql`
	query GetComments($orderId: ID!) {
		getComments(orderId: $orderId) {
			id
			message
			createdBy
			createdAt
		}
	}
`;

export const ADD_COMMENT = gql`
	mutation AddComment($comment: CommentInputDTO!) {
		addComment(comment: $comment) {
			id
			message
			createdBy
			createdAt
		}
	}
`;

export const APPROVE_ALL_ORDERS = gql`
	mutation ApproveAllOrders($filter: OrderFilter!) {
		approveAllOrders(filter: $filter)
	}
`;

export const SUBSCRIPTION_ORDER_COMMENTS = gql`
	subscription OnOrderComment($orderId: Long!) {
		orderComment(orderId: $orderId) {
			comments {
				id
				message
				createdBy
				createdAt
			}
		}
	}
`;

export const ACCEPT_ORDER_AMENDMENT = gql`
	mutation AcceptOrderAmendment($orderId: Long!, $amendmentId: String!) {
		acceptOrderAmendment(orderId: $orderId, amendmentId: $amendmentId) {
			id
			amount
			chargedToPartner
			paymentMethod
			productDiscountAssumedByPartner
			deliveryDiscountAssumedByPartner
			receivedFunds
			deliveryFee
			finalStatus
			partnerPayment
			acceptedAfterOrderPeriod
		}
	}
`;

export const REJECT_ORDER_AMENDMENT = gql`
	mutation RejectOrderAmendment($orderId: Long!, $amendmentId: String!, $rejectedReason: String) {
		rejectOrderAmendment(orderId: $orderId, amendmentId: $amendmentId, rejectedReason: $rejectedReason) {
			id
		}
	}
`;

export const GET_AMENDMENT_HISTORY = gql`
	query GetAmendmentsHistory($orderId: ID!) {
		getAmendmentsHistory(orderId: $orderId) {
			id
			orderId
			effectiveDate
			createdAt
			createdBy
			amount
			chargedToPartner
			paymentMethod
			productDiscountAssumedByPartner
			deliveryDiscountAssumedByPartner
			deliveryPromotionAssumedByPartner
			deliveryDiscountSSP
			deliveryDiscountAssumedByPartner100
			deliveryDiscountAssumedByPartnerAndGlovo
			promotionAssumedByPartner
			acceptedAt
			acceptedBy
			rejectedAt
			rejectedBy
			rejectedReason
			comment
			receivedFunds
			deliveryFee
			finalStatus
			waitingTime
			files {
				fileId
				filename
				size
			}
		}
	}
`;

export const GET_ORDER_APPROVAL = gql`
	query OrderApproval($orderId: ID!) {
		orderApproval(orderId: $orderId) {
			orderId
			approvedAt
			approvedBy
			approvedByEmail
		}
	}
`;

export const GET_STORE_ADDRESSES_DATA = gql`
	query StoreAddressData($storeAddressIds: [Int]) {
		storeAddressData(storeAddressIds: $storeAddressIds) {
			id
			storeName
			address
			storeAddressId
			promoCalculationType
		}
	}
`;

export const GET_TOTALS_AFTER_PERIOD = gql`
	query ApprovedAfterPeriodTotals($filter: OrderFilter) {
		approvedAfterPeriodTotals(filter: $filter)
	}
`;

export const GET_TOTALS_DISPUTES = gql`
	query DisputesTabTotals($filter: OrderFilter) {
		disputesTabTotals(filter: $filter)
	}
`;
