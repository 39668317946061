import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { QueryOptions, useMutation } from '@apollo/client';
import { clientGraphql } from 'api';
import { APPROVE_ALL_ORDERS, APPROVE_ORDER_BY_ID, EDIT_ORDER } from 'api/orders';
import { normalizeFilter } from 'helpers/order';
import { Direction, IAmendment, IFilter, IFormEdit, IOrder, ITotals, OrderSorting } from 'interfaces/order';
import { useSelector } from 'store';
import {
	changeOrdersFilter,
	clearAllOrders,
	clearOrdersFilter,
	fetchOrders as fetchOrdersAction,
	fetchTotals as fetchTotalsAction,
	setOrdersPage,
	setOrdersPageSize,
	updateOrder as updateOrderAction,
} from 'store/orders/actions';
import { isNumber } from 'underscore';

import useToast from './useToast';

export interface IUseOrders {
	items: IOrder[];
	filter: IFilter;
	total: number;
	onload: boolean;
	wasLoaded: boolean;
	totals?: ITotals;
	loadingTotals: boolean;
	pagionation: {
		page: number;
		pageSize: number;
	};
	fetchOrders(): void;
	fetchTotals(): void;
	changeFilter(field: keyof IFilter, value: IFilter[typeof field]): void;
	clearFilters(dates?: [Date, Date]): void;
	clearOrders(): void;
	setPage(page: number): void;
	setPageSize(pageSize: number): void;
	approveOrderById(orderId: IOrder['id'], refetchQueries?: QueryOptions[]): Promise<unknown>;
	changeSorting(sortBy: OrderSorting): void;
	editOrder(input: IFormEdit, orderId: IOrder['id'], refetchQueries?: QueryOptions[]): Promise<IAmendment | undefined>;
	approveAllOrders(): Promise<boolean>;
	updateOrder(newOrder: IOrder): void;
	setAbortRef(AbortController: AbortController): void;
	aborterRef: AbortController;
	approveAllLoading: boolean;
	getIsWaitingTimeAvailable: () => boolean;
}

export const useOrders = (): IUseOrders => {
	const { items, filter, pagionation, total, onload, wasLoaded, totals, loadingTotals } = useSelector((state) => state.orders);
	const [approveOrderByIdMutation] = useMutation(APPROVE_ORDER_BY_ID);
	const [submitOrderAmendmentMutation] = useMutation<{ submitOrderAmendment: IAmendment }>(EDIT_ORDER);
	const [approveAllOrdersMutation] = useMutation(APPROVE_ALL_ORDERS);
	const dispatch = useDispatch();
	const { t } = useTranslation(['orders', 'errors']);
	const { toast } = useToast();
	const [aborterRef, setAbortRef] = useState(new AbortController());
	const [approveAllLoading, setApproveAllLoading] = useState(false);

	const fetchOrders = () => {
		return dispatch(fetchOrdersAction(filter, pagionation));
	};

	const fetchTotals = () => {
		return dispatch(fetchTotalsAction(filter, aborterRef));
	};

	const aboartRequest = () => {
		if (aborterRef && typeof setAbortRef === 'function') {
			aborterRef.abort();
			setAbortRef(new AbortController());
		}
	};

	const changeFilter = (field: keyof IFilter, value: IFilter[typeof field]) => {
		aboartRequest();
		dispatch(changeOrdersFilter(field, value));
	};

	const clearFilters = (dates?: [Date, Date]) => {
		aboartRequest();
		dispatch(clearOrdersFilter(dates));
	};

	const clearOrders = () => {
		dispatch(clearAllOrders());
	};

	const setPage = (page: number) => {
		dispatch(setOrdersPage(page));
	};

	const setPageSize = (pageSize: number) => {
		dispatch(setOrdersPageSize(pageSize));
	};

	const approveOrderById = (orderId: IOrder['id'], refetchQueries?: QueryOptions[]) => {
		return approveOrderByIdMutation({
			variables: { orderId: Number(orderId) },
			refetchQueries,
			awaitRefetchQueries: true,
		}).catch(() => {
			toast(t('errors:serverError'), { type: 'error' });
		});
	};

	const changeSorting = (sortBy: OrderSorting) => {
		if (filter.sorting?.sortBy === sortBy) {
			if (filter.sorting?.direction === Direction.DESC) return changeFilter('sorting', undefined);
			changeFilter('sorting', {
				...filter.sorting,
				direction: filter.sorting?.direction === Direction.ASC ? Direction.DESC : Direction.ASC,
			});
		} else {
			changeFilter('sorting', {
				sortBy,
				direction: Direction.ASC,
			});
		}
	};

	const updateOrder = (newOrder: IOrder) => {
		dispatch(updateOrderAction(newOrder));
	};

	const editOrder = (input: IFormEdit, orderId: IOrder['id'], refetchQueries?: QueryOptions[]): Promise<IAmendment | undefined> => {
		return submitOrderAmendmentMutation({
			variables: { input: { ...input, orderId } },
			refetchQueries,
			awaitRefetchQueries: true,
		})
			.then((res) => res.data?.submitOrderAmendment)
			.catch((error) => {
				error?.message?.split('\n').forEach((err: string) => {
					toast(t(`errors:${err}`), { type: 'error' });
				});
				return undefined;
			});
	};

	const approveAllOrders = (): Promise<boolean> => {
		setApproveAllLoading(true);
		return approveAllOrdersMutation({
			variables: { filter: normalizeFilter(filter) },
		})
			.then(async () => {
				toast(t('approvedAll'), { type: 'success' });
				await clientGraphql.clearStore();
				await fetchOrders();
				return true;
			})
			.catch((error) => {
				error?.message?.split('\n').forEach((err: string) => {
					toast(err, { type: 'error' });
				});
				return false;
			})
			.finally(() => setApproveAllLoading(false));
	};

	const getIsWaitingTimeAvailable = useCallback(() => {
		const hasAtLeastOneWaitingTime = items?.some((item) => isNumber(item.waitingTime));
		return hasAtLeastOneWaitingTime;
	}, [items]);

	return {
		items,
		filter,
		pagionation,
		total,
		onload,
		wasLoaded,
		totals,
		loadingTotals,
		fetchOrders,
		changeFilter,
		clearFilters,
		clearOrders,
		setPage,
		setPageSize,
		approveOrderById,
		changeSorting,
		editOrder,
		approveAllOrders,
		updateOrder,
		fetchTotals,
		setAbortRef,
		aborterRef,
		approveAllLoading,
		getIsWaitingTimeAvailable,
	};
};
