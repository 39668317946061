import { gql } from '@apollo/client';

export default gql`
	enum BulkUploadStatus {
		PROCESSING
		INTERRUPTED
		FAILED
		SUCCESS
	}

	type BulkUploadFileDto {
		fileId: String
		filename: String
		createdAt: DateTime
		status: BulkUploadStatus
		createdBy: String
		comment: String
		errorFileId: String
		processedFileId: String
		rowsCount: Int
		processedRows: Int
	}
`;
