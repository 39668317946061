export const getInMemoryCacheTypePolicies = () => {
	return {
		VchasnoDoc: {
			keyFields: ['title', 'number', 'id'],
		},
		StoreAddressDto: {
			keyFields: ['id', 'contractNumber', 'address'],
		},
		BulkUploadFileDto: {
			keyFields: ['fileId'],
		},
	};
};
