import { format } from 'date-fns';
import { ICustomerForm } from 'interfaces/customer';
import { IStoreAddressForm } from 'interfaces/storeAddress';

export enum STORE_TYPE {
	NEW = 'NEW',
	TEMPORARY = 'TEMPORARY',
	APPROVED = 'APPROVED',
	DEACTIVATED = 'DEACTIVATED',
}

export const normilizeInput = (input: IStoreAddressForm | ICustomerForm, isEdit = false) => {
	const normalizedInput = {
		...input,
		status: undefined,
		effectiveUntil: input.effectiveUntil && !isEdit ? format(input.effectiveUntil, 'yyyy-MM-dd') : undefined,
		effectiveDate: input.effectiveDate ? format(input.effectiveDate, 'yyyy-MM-dd') : undefined,
	};

	if ('waitingTimeStartDate' in input) {
		delete (normalizedInput as IStoreAddressForm).waitingTime;

		return {
			...normalizedInput,
			waitingTimeStartDate: input.waitingTimeStartDate ? format(input.waitingTimeStartDate, 'yyyy-MM-dd') : undefined,
		};
	}

	return normalizedInput;
};
