import { STORE_TYPE } from 'helpers/storeAddresses';

import { IAction } from './types';
import {
	CHANGE_STORE_ADRESSES_FILTER,
	FETCH_STORE_ADRESSES_FAILURE,
	FETCH_STORE_ADRESSES_START,
	FETCH_STORE_ADRESSES_SUCCESS,
	IState,
	SET_STORE_ADRESSES_PAGE,
	SET_STORE_ADRESSES_PAGE_SIZE,
	SET_STORE_DATA,
} from './types';

const initialState: IState = {
	items: [],
	total: 0,
	onload: true,
	wasLoaded: false,
	filter: {
		searchQuery: '',
		status: [STORE_TYPE.APPROVED, STORE_TYPE.TEMPORARY],
	},
	pagionation: {
		page: 0,
		pageSize: 100,
	},
};

const reducer = (state = initialState, action: IAction): IState => {
	switch (action.type) {
		case FETCH_STORE_ADRESSES_START:
			return {
				...state,
				onload: true,
			};
		case FETCH_STORE_ADRESSES_SUCCESS:
			return {
				...state,
				items: action.payload.items,
				total: action.payload.total,
				onload: false,
				wasLoaded: true,
			};
		case FETCH_STORE_ADRESSES_FAILURE:
			return {
				...state,
				onload: false,
				wasLoaded: true,
				items: [],
				total: 0,
			};
		case SET_STORE_ADRESSES_PAGE:
			return {
				...state,
				pagionation: {
					...state.pagionation,
					page: action.payload,
				},
			};
		case SET_STORE_ADRESSES_PAGE_SIZE:
			return {
				...state,
				pagionation: {
					...state.pagionation,
					pageSize: action.payload,
				},
			};
		case CHANGE_STORE_ADRESSES_FILTER:
			return {
				...state,
				filter: {
					...state.filter,
					[action.payload.field]: action.payload.value,
				},
				pagionation: {
					page: 0,
					pageSize: 100,
				},
			};
		case SET_STORE_DATA:
			return {
				...state,
				items: state.items.map((store) => {
					const newStore = action.payload;
					const isTargetStore = store.storeAddressId === newStore.storeAddressId;

					if (isTargetStore)
						return {
							...newStore,
							gen: newStore.gen ?? store.gen,
							category: newStore.category ?? store.category,
							storeAddressId: newStore.storeAddressId ?? store.storeAddressId,
						};

					return store;
				}),
			};
		default:
			return state;
	}
};

export default reducer;
