import { gql } from '@apollo/client';

import bulk from './bulk';
import inputs from './inputs';
import invoices from './invoices';
import mutatation from './mutatation';
import notification from './notification';
import order from './order';
import partnerFile from './partnerFile';
import payoutSchedule from './payoutSchedule';
import query from './query';
import scalars from './scalars';
import subscription from './subscription';
import user from './user';
import vchasnoDoc from './vchasnoDoc';
import waitingTime from './waitingTime';

export default gql`
	${inputs}
	${order}
	${user}
	${scalars}
	${mutatation}
	${query}
	${subscription}
	${notification}
	${payoutSchedule}
	${vchasnoDoc}
	${invoices}
	${bulk}
	${partnerFile}
	${waitingTime}
`;
